$(document).ready(function() {
  $(".arrow").on("click", function(e) {
    e.preventDefault();
    $("html, body").animate({
      scrollTop: 0
    }, 800);
  });
  // remove effect from boxes o firmie
  if (window.matchMedia("(min-width: 1024px)").matches) {
    $(".about__img").removeClass("about__background");
  }
  // download list
  var $trigger = $(".download-list li");
  var $content = $(".download-content");
  $trigger.on("click", function(e) {
    e.preventDefault();
    var index = $(this).index();
    $content.children().slideUp();
    $content.children().eq(index).slideDown();
    $trigger.removeClass("download-active");
    $(this).addClass("download-active");
  });
  // offert list
  $(".list__producent").hide();
  $(".list__link").click(function(e) {
    e.preventDefault();
    // (".list li p").slideUp();
    // $(".list li a").removeClass("offert-active");
    $(this).toggleClass("offert-active");
    $(this).siblings().slideToggle();
  });
  // gallery switcher
  $(".gallery__categories ul li a").click(function(e) {
    e.preventDefault();
    $(".gallery__categories ul li a").removeClass("gallery-active");
    $(this).addClass("gallery-active");
  });
  // scroll main button orange
  /* $(".main-button__circle").on("click", function() {
    $("html, body").animate({
      scrollTop: $(".main-button__circle").offset().top - $(".nav").height()
    }, 800);
  });*/
  // scroll on a
  if (window.matchMedia("(min-width: 1550px)").matches) {
    $("a.scroll").on("click", function() {
      // if ( $(this).attr("href") === true) {
      $("html, body").animate({
        scrollTop: $( $(this).attr("href")).offset().top - 80
      }, 800);
      // }
    });
  } else {
    $("a.scroll").on("click", function() {
      // var attr = $(this).attr("href");
      // if ((typeof attr !== typeof undefined && attr !== false)) {
      $("html, body").animate({
        scrollTop: $( $(this).attr("href")).offset().top - 70
      }, 800);
      // }
    });
  }
  // dropdown roll on mobile
  if (window.matchMedia("(max-width: 1024px)").matches) {
    $("#offert").on("click", function(e) {
      e.preventDefault();
      $(this).toggleClass("dropdown-down");
      $(".dropdown").slideToggle(function() {
        if (window.screen.height < ($(".nav").height() + $(".nav__list").height())) {
          $("body").addClass("no-scroll");
        } else {
          $("body").removeClass("no-scroll");
        }
      });
      var $topBarHeight = parseFloat( $(".nav").height() );
      if ( window.screen.width < 1024) {
        $(".nav__list").height( window.screen.height - $topBarHeight );
      }
      if (!$(this).hasClass("dropdown-down")) {
        $(".nav__list").css("height", "auto");
      }
    });
  }
  // menu hover effect
  if (window.matchMedia("(min-width: 1024px)").matches) {
    $(".header__nav ul li").on("mouseenter", function() {
      $(".header__nav ul li.active-card").addClass("current-active-dis");
      $(".header__nav ul li.active-card").removeClass("active-card");
    });
    $(".header__nav   ").on("mouseleave", function() {
      $(".header__nav ul li.current-active-dis").addClass("active-card");
      $(".header__nav ul li.current-active-dis").removeClass("current-active-dis");
    });

    $(".header__nav ul li").on("mouseenter", function() {
      $(this).addClass("current-active");
    });
    $(".header__nav ul li").on("mouseleave", function() {
      $(".header__nav ul li").removeClass("current-active");
    });
  }
  // menu hamburger /////////////////////////

  $("#hamburger-container").on("click", function() {
    $(".header").toggleClass("header-active");
    $(".menu-button-bar1").toggleClass("animate1");
    $(".menu-button-bar2").toggleClass("animate2");
    $(".menu-button-bar3").toggleClass("animate3");
    if (!$(".header").hasClass("header-active")) {
      $(".dropdown").css("display", "none");
      $(".nav__list").css("height", "auto");
    }
    $("#offert").removeClass("dropdown-down");
  });

  // ,menu scroll //////////////////////////////////////////

  $(window).on("load scroll", function() {
    var currentScroll = $(this).scrollTop();
    if (currentScroll > 50) {
      $(".header").addClass("active");
    } else {
      $(".header").removeClass("active");
    }
    if (currentScroll > 200) {
      $(".arrow").fadeIn();
    } else {
      $(".arrow").fadeOut();
    }
  });

  // iifinite carousel
  $(function() {
	$( '#carousel_ul li img' ).hover(
		function( e ) {
			$( '#carousel_ul' ).pause();
		}, function( e ) {
			$( '#carousel_ul' ).resume();
		}
	);

	slide();
});

function slide() {
    var item_width = $('#carousel_ul li').outerWidth( true );
    var left_indent = 0 - item_width;

    $( '#carousel_ul' ).animate( {'left' : left_indent}, 6000, 'linear', function() {
        $( '#carousel_ul li:last' ).after( $('#carousel_ul li:first') );
        $( '#carousel_ul' ).css( {'left' : '0px'} );
	slide();
    });
}

/*function basename( error ) {
	var path = error.currentTarget.src;
	var slash_pos = path.lastIndexOf( '/' );
	var filename = path.substring( slash_pos+1 );
	var underscore_pos = filename.lastIndexOf( '_' );
	var basename = filename.substring( 0, underscore_pos );
	return basename;
}*/
  // svg //////////////////////////////////////

  $("img.svg").each(function() {
    var $img = $(this);
    var imgID = $img.attr("id");
    var imgClass = $img.attr("class");
    var imgURL = $img.attr("src");

    $.get(imgURL, function(data) {
          // Get the SVG tag, ignore the rest
      var $svg = $(data).find("svg");

          // Add replaced image"s ID to the new SVG
      if (typeof imgID !== "undefined") {
        $svg = $svg.attr("id", imgID);
      }
          // Add replaced image"s classes to the new SVG
      if (typeof imgClass !== "undefined") {
        $svg = $svg.attr("class", imgClass + " replaced-svg");
      }

          // Remove any invalid XML tags as per http://validator.w3.org
      $svg = $svg.removeAttr("xmlns:a");

          // Replace image with new SVG
      $img.replaceWith($svg);
    },  "xml");
  });
});

$(document).ready(function() {
      $('html, body').hide();

      if (window.location.hash) {
          setTimeout(function() {
              $('html, body').show().scrollTop(0);
              $('html, body').animate({
                  scrollTop: $(window.location.hash).offset().top - 70
                  }, 1000)
          }, 0);
      }
      else {
          $('html, body').show();
      }
  });
